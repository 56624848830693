import { configureCrypto, setCryptoConfig } from "core/model/crypto/singleton";
import { LoggingType, configureLogging } from "core/model/logging";
import { configureRealUserMonitoring } from "core/model/real-user-monitoring";
import { AppType } from "core/types";
import React, { useEffect } from "react";
import { setupStore } from "reduxentities/store";
import PandaProvider, { configurePanda } from "./PandaProvider";

export type ServiceFactoryAppString =
  | "ACP"
  | "B2C"
  | "Careprovider"
  | "Clinic"
  | "ProviderSearch";

export type Services = {
  logger: LoggingType;
  persistor: any;
  store: any;
};

export function ServicesProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  const pandaSpecs = configurePanda(app);

  useEffect(() => {
    const crypto = configureCrypto();

    setCryptoConfig(crypto);
  }, []);

  return <PandaProvider specs={pandaSpecs}>{children}</PandaProvider>;
}

export default (app: ServiceFactoryAppString): Services => {
  configureRealUserMonitoring(app);

  const logger = configureLogging(app);
  const { persistor, store } = setupStore(true);

  return {
    store,
    logger,
    persistor,
  };
};
