import { ENV_DEVELOPMENT, getConfig } from "core/model/config";
import { activateEnvSwitch, isProd } from "core/model/utils/featureFlags";
import { AppType, Env, EnvContext as EnvContextType } from "core/types";
import { BaseEventSchema } from "core/validationSchemas/tracking";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTracking } from "react-tracking";

const defaultEnvContext: EnvContextType = {
  app: -1 as AppType,
  env: "development",
  config: null,
  setEnv: () => {},
};

const EnvContext = createContext<EnvContextType>(defaultEnvContext);

export function EnvProvider({
  app,
  children,
}: {
  app: AppType;
  children: ReactNode;
}) {
  const defaultEnv = activateEnvSwitch
    ? window.localStorage.getItem("env") || process.env.ENV
    : process.env.ENV;

  const [env, setEnv] = useState<Env>((defaultEnv ?? "development") as Env);
  const { Track } = useTracking<DeepPartial<BaseEventSchema>>({
    platform_information: { env },
  });

  const saveEnv = useCallback((newEnv: Env) => {
    window.localStorage.clear();
    window.localStorage.setItem("env", newEnv);
    setEnv(newEnv);
  }, []);

  const config = useMemo(() => getConfig(env), [env]);

  return (
    <Track>
      <EnvContext.Provider value={{ app, env, config, setEnv: saveEnv }}>
        {children}
      </EnvContext.Provider>
    </Track>
  );
}

export function useEnvContext() {
  const context = useContext(EnvContext);
  if (!isProd && (!context || context === defaultEnvContext)) {
    console.error(
      "You're attempting to use useEnvContext outside EnvContext.Provider",
    );
  }
  return context;
}

export function TestEnvProvider({
  app,
  children,
}: {
  app: AppType;
  children: ReactNode;
}) {
  return (
    <EnvContext.Provider
      value={{
        app,
        env: ENV_DEVELOPMENT,
        config: getConfig(ENV_DEVELOPMENT),
        setEnv: () => {},
      }}
    >
      {children}
    </EnvContext.Provider>
  );
}
