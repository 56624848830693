export const blue = {
  50: "#ECF1FC",
  100: "#DDE9FF",
  200: "#C4D8FD",
  300: "#93B7FB",
  400: "#4E8EFB",
  500: "#3D72DB",
  600: "#2B53C6",
  700: "#1E3DA6",
  800: "#233684",
  900: "#222D48",
} as const;
