export const purple = {
  50: "#EEEFFF",
  100: "#DCDCF9",
  200: "#C5CBFE",
  300: "#AEB6FF",
  400: "#8793FD",
  500: "#6373FF",
  600: "#5E36FC",
  700: "#4906E5",
  800: "#3504A7",
  900: "#20016A",
} as const;
