export const red = {
  50: "#FFEFEF",
  100: "#FFE3E3",
  200: "#FAC7CF",
  300: "#FFADB8",
  400: "#FA8E9E",
  500: "#EF5769",
  600: "#EC3251",
  700: "#D41444",
  800: "#A81036",
  900: "#520000",
} as const;
