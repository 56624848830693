import { generateForegroundColorObject } from "../utils";
import { bcpAccent } from "./bcpAccent";
import { beige } from "./beige";
import { blue } from "./blue";
import { gray } from "./gray";
import { green } from "./green";
import { magenta } from "./magenta";
import { orange } from "./orange";
import { purple } from "./purple";
import { red } from "./red";
import { steelBlue } from "./steelBlue";
import { yellow } from "./yellow";

export const white = "#FFF";

export const commonColors = {
  black: "#000",
  white,
  beige: { ...beige, ...generateForegroundColorObject(beige) },
  gray: { ...gray, ...generateForegroundColorObject(gray) },
  green: { ...green, ...generateForegroundColorObject(green) },
  magenta: { ...magenta, ...generateForegroundColorObject(magenta) },
  orange: { ...orange, ...generateForegroundColorObject(orange) },
  purple: { ...purple, ...generateForegroundColorObject(purple) },
  red: { ...red, ...generateForegroundColorObject(red) },
  steelBlue: {
    ...steelBlue,
    ...generateForegroundColorObject(steelBlue),
  },
  bcpAccent: {
    ...bcpAccent,
    ...generateForegroundColorObject(bcpAccent),
  },
  blue: {
    ...blue,
    ...generateForegroundColorObject(blue),
  },
  yellow: { ...yellow, ...generateForegroundColorObject(yellow) },
} as const;

export type CommonColors = typeof commonColors;
