import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpOptions,
  InMemoryCache,
  Operation,
  createHttpLink,
  split,
} from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { onError } from "@apollo/client/link/error";
import { useEnvContext } from "context/EnvContext";
import { APP_B2C, APP_PROVIDER_SEARCH } from "core/consts";
import transport from "core/model/api/transport";
import { getApp } from "core/model/config";
import { activateWebSocket, isProd } from "core/model/utils/featureFlags";
import { ConfigType } from "core/types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { State } from "reduxentities/selectors/index";
import cryptoLinks from "../encryption";
import { operationTimesLinks } from "../utils/operationTimes";
import { WebSocketLink } from "./WebSocketLink";
import { WebSocketConfig } from "./config";
import { useGetWebSocketEventHandlers } from "./utils";

let authToken: string | undefined = undefined;
const setAuthToken = (token: string | undefined) => {
  authToken = token;
};
const getAuthToken = () => authToken;

type RestParams = {
  fragment: string;
  queryName: string;
  restMethod: string;
  restPathAndQuery: string;
};

type Variables = {
  [key: string]: unknown;
  _rest: RestParams;
};
const customFetch: HttpOptions["fetch"] = (
  uri,
  apolloOptions: (RequestInit & { variables?: Variables }) | undefined,
) => {
  if (typeof apolloOptions?.body !== "string") {
    throw new Error("Expected apolloOptions.body to be a string.");
  }
  const body = JSON.parse(apolloOptions.body);
  // 'query' not currently used in the BE and roughly double the size
  // of the 'fragment' in the network payload
  // when transitioning to using 'query' for caching, look into
  // optimizing the string size
  body.query = undefined;

  body.variables = apolloOptions.variables;

  const route =
    uri instanceof URL ? uri.href : typeof uri === "string" ? uri : null;
  if (route === null) {
    throw new Error("URI must be a string or URL.");
  }

  return transport.post({
    route,
    body,
    options: {
      routeName: "apollo",
      isApollo: true,
    },
  });
};

export function getOperationRestParams(operation: Operation): RestParams {
  const body = operation.query.loc?.source.body;
  const bodyRegex = /{([\s\S]*)}/;
  const [, queryBody] = body?.match(bodyRegex) ?? [];
  const fragmentRegex = /rest\([\s\S]*\)[\s\S]*?({[\s\S]*})/;
  const [, fragmentBody] = queryBody?.match(fragmentRegex) ?? [];
  const fragment = fragmentBody?.replace(/ /g, "");

  const restRegex = /@rest\(([\s\S]*)\)/;
  const [, restDef] = body?.match(restRegex) ?? [];

  const restDefObj = Object.fromEntries(
    restDef
      .replace(/\n/g, "%%")
      .replace(/",/g, "%%")
      .split("%%")
      .map((s) => s.trim())
      .filter(Boolean)
      .map((p) => p.replace(/ /g, "").replace(/"/g, "").split(":")),
  );

  const operationNameRegex = /{([\s\S]*)@rest([\s\S]*)}/;
  const [, queryNameDef] = body?.match(operationNameRegex) ?? [];
  const queryName = String(queryNameDef)
    .replace(/ |\n/g, "")
    .replace(/\(.*\)/g, "");
  // const or = (operation as any).query.definitions[0].selectionSet.selections[0].name

  let restPathAndQuery = restDefObj?.path || null;
  if (restPathAndQuery && operation.variables) {
    Object.entries(operation.variables).forEach(([varName, varValue]) => {
      restPathAndQuery = restPathAndQuery.replace(
        new RegExp(`{args.${varName}}`),
        varValue,
      );
    });
  }

  const restMethod = restDefObj?.method || "GET";

  return {
    restMethod,
    restPathAndQuery,
    queryName,
    fragment,
  };
}

// setup error logging
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const app = getApp();
  if (graphQLErrors)
    graphQLErrors.map(({ locations, message, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (
      networkError.message?.includes("401") &&
      window.location.pathname !== "/auth"
    )
      window.location.href = "/logout?reason=unauthorized";

    // to do: extend to all apps
    if (
      app &&
      ([APP_PROVIDER_SEARCH, APP_B2C] as number[]).includes(app) &&
      networkError.message?.includes("429")
    )
      window.location.href = "/error?reason=429";
  }
});

function getUri(config: ConfigType | null): string {
  if (config?.backend.host == null) {
    return "badconfig";
  }

  return `${config.backend.host}:${config.backend.port}`;
}

const ApolloClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { app, config, env } = useEnvContext();
  const { getTrackingData, trackEvent } = useTracking();
  const getWebSocketEventHandlers = useGetWebSocketEventHandlers();

  setAuthToken(useSelector((s: State) => s?.auth?.credentials?.token));

  const wsLink = useMemo(() => {
    if (!activateWebSocket(app)) return null;

    return new WebSocketLink({
      url: `wss://${getUri(config)}/websocket?apollo=true`,
      keepAlive: WebSocketConfig.PING_REFRESH_RATE,
      retryAttempts: WebSocketConfig.RETRY_ATTEMPTS,
      on: getWebSocketEventHandlers(),
      connectionParams: () => {
        const token = getAuthToken();
        if (!token) {
          return {};
        }
        return {
          Authorization: `Bearer ${token}`,
          app: getApp(),
        };
      },
      lazy: true,
    });
  }, [env, config]);

  const httpLink = useMemo(
    () =>
      createHttpLink({
        fetch: customFetch,
      }),
    [env],
  );

  const requestEndpointLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      const variables: Variables = {
        ...operation.variables,
        _rest: getOperationRestParams(operation),
      };
      return {
        fetchOptions: {
          variables,
        },
      };
    });
    return forward(operation);
  });

  const nameLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: `/graphql?${operation.operationName}`,
    }));
    return forward ? forward(operation) : null;
  });

  const splitLink = useMemo(
    () =>
      split(
        ({ query }) => {
          const definition = query.definitions[0];
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink ?? ApolloLink.from([]), // activateWebSocket
        ApolloLink.from([requestEndpointLink, nameLink, httpLink]),
      ),
    [env],
  );

  // setup client
  const client = useMemo(() => {
    // setup cache state
    const cache = new InMemoryCache({
      // see https://github.com/apollographql/apollo-client/issues/11808
      possibleTypes: {
        All: [".*"],
      },
      typePolicies: {
        All: {
          merge: true,
        },
      },
    });

    return new ApolloClient({
      cache,
      link: ApolloLink.from([
        ...operationTimesLinks(trackEvent),
        ...cryptoLinks(getTrackingData()),
        errorLink,
        splitLink,
      ]),
    });
  }, [env]);

  if (!isProd) {
    loadDevMessages();
    loadErrorMessages();
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
