import transport from "core/model/api/transport";
import { AnyObject, AppType, SealdIdentityCheck } from "core/types";
import jwtDecode from "jwt-decode";

export default {
  checkToken(): Promise<void> {
    return transport.get({
      route: `/auth/check_token`,
      queryParams: null,
      options: {
        routeName: "checkToken",
      },
    });
  },
  getSalt({ user }: { user: string }): Promise<{ salt: string }> {
    return transport.get({
      route: `/auth/salt/${user}`,
      queryParams: null,
      options: {
        routeName: "authSalt",
      },
    });
  },
  oauthCheck({ email }: { email: string }): Promise<{ url: string } | null> {
    return transport.post({
      route: `/oauth-check`,
      body: {
        user: email,
      },
      queryParams: null,
      options: {
        routeName: "oauthCheck",
      },
    });
  },
  login({
    activateSeald,
    device,
    subscription_info,
    token,
  }: {
    activateSeald?: boolean;
    device?: { device_id: string; device_type: string };
    subscription_info?: PushSubscription | null | undefined;
    token: string;
  }): Promise<any> {
    return transport.post({
      route: "/auth/login",
      body: {
        ...(device ?? {}),
        subscription_info,
      },
      queryParams: activateSeald ? { seald: true } : null,
      options: {
        token,
        routeName: "login",
      },
    });
  },
  logout(): Promise<null | { sso_logout_url: string }> {
    return transport.post({
      route: "/auth/logout",
      body: {},
      queryParams: null,
      options: {
        routeName: "logout",
      },
    });
  },
  authenticate({
    password_hashed,
    salt,
    user,
  }: {
    password_hashed: string | undefined;
    salt: string;
    user: string;
  }): Promise<{ expiration: number; token: string }> {
    return transport
      .post<{ id_token: string }>({
        route: "/auth/check",
        body: {
          user,
          salt,
          password_hashed,
        },
        queryParams: null,
        options: {
          routeName: "authenticate",
        },
      })
      .then((res) => {
        const decoded = jwtDecode<any>(res.id_token);
        if (res.id_token) {
          localStorage.setItem("id_token", res.id_token);
        }

        return {
          token: res.id_token,
          expiration: decoded.exp,
        };
      });
  },
  resetPassword(email: string, app: number): Promise<any> {
    return transport.post({
      route: `/reset/initiate`,
      body: { email, app },
      queryParams: { routeName: "passwordReset" },
    });
  },
  verifyToken(token: string): Promise<{ app: AppType; email: string } | null> {
    return transport.get({
      route: `/reset/verify/${token}`,
      queryParams: {},
      options: { routeName: "verifyToken" },
    });
  },
  changePassword({
    password_hashed,
    salt,
    timestamp,
    token,
    user,
  }: {
    password_hashed: string | undefined;
    salt: string | undefined;
    timestamp: string;
    token: string;
    user: string;
  }): Promise<AnyObject> {
    return transport.post({
      route: `/reset/change/${token}`,
      body: {
        user,
        password_hashed,
        salt,
      },
      queryParams: {
        routeName: "changePassword",
        timestamp,
      },
    });
  },
  inAppChangePassword({
    id,
    new_password_hashed,
    old_password_hashed,
    salt,
    timestamp,
  }: {
    id: number;
    new_password_hashed: string | undefined;
    old_password_hashed: string | undefined;
    salt: string | undefined;
    timestamp: string;
  }): Promise<any> {
    return transport.post({
      route: `/change/${id}`,
      body: {
        old_password_hashed,
        new_password_hashed,
        salt,
      },
      queryParams: { timestamp },
      options: {
        routeName: "inAppChangePassword",
      },
    });
  },
  sendChallenge2MR(
    token: string,
    queryParams: AnyObject | null = null,
  ): Promise<{
    must_authenticate: boolean;
    seald_two_man_rule_key: string;
    seald_two_man_rule_session_id: string;
  }> {
    return transport.post({
      route: `/seald/sendchallenge`,
      body: {},
      queryParams,
      options: {
        routeName: "sendChallenge2MR",
        token,
      },
    });
  },
  resetIdentity(token: string): Promise<{
    seald_database_key: string;
    seald_user_license_token: string;
  }> {
    return transport.post({
      route: `/seald/identity/reset`,
      body: {},
      queryParams: null,
      options: {
        routeName: "resetIdentity",
        token,
      },
    });
  },
  checkIdentity(token: string): Promise<SealdIdentityCheck> {
    return transport.post({
      route: `/seald/identity/check`,
      body: {},
      queryParams: null,
      options: {
        routeName: "checkIdentity",
        token,
      },
    });
  },
  testEnvPasswordCheck({ password }: { password: string }): Promise<void> {
    return transport.post({
      route: `/auth/testenv`,
      body: { password },
      queryParams: null,
      options: {
        routeName: "testEnvPasswordCheck",
      },
    });
  },
};
