import { ApolloLink, SingleExecutionResult, fromPromise } from "@apollo/client";
import { hasCrypto } from "core/model/crypto";
import decryptPatcher from "./decryptPatcher";
import { getEncryptionDefinition } from "./queryKeys";
import { ContextType } from "./shared";

async function encryptionPromise(
  operationName: string,
  input: AnyObject,
  context: ContextType,
): Promise<SingleExecutionResult<AnyObject>> {
  const { encryptionContext } = context;
  const definition = getEncryptionDefinition(operationName, "encrypt");
  if (
    input != null &&
    definition &&
    hasCrypto() &&
    encryptionContext?.decrypt === true
  ) {
    const encryptedInput = await decryptPatcher({
      mode: "encrypt",
      data: input,
      encryptionDefinition: definition,
      encryptionContext,
    });
    return encryptedInput;
  }

  return input;
}

const encryptionLink = new ApolloLink((operation, forward) => {
  const input = operation.variables.input;
  const operationName = operation.operationName;
  const context = operation.getContext() as ContextType;

  return fromPromise(encryptionPromise(operationName, input, context)).flatMap(
    (encryptedInput: AnyObject) => {
      operation.variables.input = encryptedInput;
      return forward(operation);
    },
  );
});

export default encryptionLink;
