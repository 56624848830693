import "core-js/es/promise/finally";
import "core/model/utils/arrays/extends";
import { ActivityProvider } from "core/model/utils/browser/ActivitySingleton";
import { useExplicitErrorHandler } from "core/model/utils/browser/ErrorHandlers";
import { AppType } from "core/types";
import CrispFrame from "ds_legacy/components/CrispFrame";
import CssBaseline from "ds_legacy/materials/baseline";
import BrowserProvider from "dsl/atoms/BrowserProvider";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import "../accessibility.css";
import "../polyfills/safari-focus-polyfill";
import "../tailwind.css";
import ContextProvider from "./ContextProvider";
import PageTracker from "./PageTracker";
import { Services } from "./ServiceFactory";

// ID of the DOM element to mount app on
const DOM_APP_EL_ID = "app";

// polyfill smooth scrolling
smoothscroll.polyfill();

function App({
  context,
  Router,
}: {
  Router: any;
  context: { app: AppType; services: Services };
}) {
  useExplicitErrorHandler();

  return (
    <BrowserProvider>
      <ActivityProvider>
        <ContextProvider services={context.services} app={context.app}>
          <CssBaseline />
          <BrowserRouter>
            <PageTracker>
              <CrispFrame>
                <Router {...context} />
              </CrispFrame>
            </PageTracker>
          </BrowserRouter>
        </ContextProvider>
      </ActivityProvider>
    </BrowserProvider>
  );
}

export default (context: { app: AppType; services: Services }, Router: any) => {
  const root = createRoot(document.getElementById(DOM_APP_EL_ID)!);
  root.render(<App context={context} Router={Router} />);
};
