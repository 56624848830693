export const green = {
  50: "#E0F9E7",
  100: "#BAF2CA",
  200: "#87E8A4",
  300: "#54DE9B",
  400: "#00CC8F",
  500: "#00B881",
  600: "#00845C",
  700: "#006144",
  800: "#003D2B",
  900: "#00291D",
} as const;
