import {
  ThemeProvider as MuiThemeProviderV5,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { NextUIProvider } from "@nextui-org/react";
import { THEME_CLASSES } from "core/consts";
import { AppType } from "core/types";
import { getTheme } from "ds_legacy/materials/theme";
import { ThemeProvider as Emotion10ThemeProvider } from "emotion-theming";
import React, { useEffect } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function useAddThemeClass({ app }: { app?: AppType }) {
  useEffect(() => {
    if (!app) return;

    const targetElement = window.IN_STORYBOOK
      ? document.getElementById("root")
      : document.body;
    if (!targetElement) {
      console.error("No appropriate target element found");
      return;
    }

    const existingThemes = Object.values(THEME_CLASSES);

    existingThemes.forEach((theme) => {
      if (theme && targetElement.classList.contains(theme)) {
        targetElement.classList.remove(theme);
      }
    });

    const themeClass = THEME_CLASSES[app];
    if (themeClass) {
      targetElement.classList.add(themeClass);
    }

    return () => {
      if (themeClass) {
        targetElement.classList.remove(themeClass);
      }
    };
  }, [app]);
}

export default function RecareThemeProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  useAddThemeClass({ app });

  return (
    <NextUIProvider>
      <Emotion10ThemeProvider theme={getTheme(app)}>
        <StyledThemeProvider theme={getTheme(app)}>
          <MuiThemeProviderV5 theme={getTheme(app)}>
            <ThemeProvider theme={getTheme(app)}>{children}</ThemeProvider>
          </MuiThemeProviderV5>
        </StyledThemeProvider>
      </Emotion10ThemeProvider>
    </NextUIProvider>
  );
}
