import { TRACK_EVENTS } from "core/consts";
import { useGetPageName } from "core/model/tracker/utils";
import { getReferrer } from "core/model/utils/urls";
import { BaseEventSchema } from "core/validationSchemas/tracking";
import { differenceInSeconds } from "date-fns";
import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { useTracking } from "react-tracking";

const trackingState: {
  previousDate: Date;
  previousPage: string | null;
  startDate: Date;
} = {
  previousDate: new Date(),
  previousPage: null,
  startDate: new Date(),
};

export default function usePageTracking() {
  const { pageName: currentPage, staticUrl } = useGetPageName();
  const match = useMatch(staticUrl || "");
  const { previousDate, previousPage, startDate } = trackingState;

  const { Track, trackEvent } = useTracking<DeepPartial<BaseEventSchema>>(
    {
      page_information: {
        page_name: currentPage || "not defined",
        path: match?.pattern.path || "not defined",
        pathname: location.pathname || "not defined",
        previous_page: previousPage || "not defined",
        referrer: getReferrer(),
      },
    },
    { dispatchOnMount: false },
  );

  useEffect(() => {
    if (currentPage !== null && currentPage !== previousPage) {
      const currentTime = new Date();
      const durationInSeconds = differenceInSeconds(currentTime, previousDate);
      const totalDurationInSeconds = differenceInSeconds(
        currentTime,
        startDate,
      );

      trackEvent({
        name: TRACK_EVENTS.PAGE_VIEW,
        page_information: {
          duration_in_seconds: durationInSeconds,
          total_duration_in_seconds: totalDurationInSeconds,
        },
      });

      trackingState.previousPage = currentPage;
      trackingState.previousDate = new Date();
    }
  }, [location.pathname]);

  return { Track };
}
