export const steelBlue = {
  50: "#D3E2EE",
  100: "#C4D9E9",
  200: "#A6C5DD",
  300: "#89B2D2",
  400: "#6B9FC7",
  500: "#4382B1",
  600: "#3A7099",
  700: "#2D5676",
  800: "#224159",
  900: "#162B3B",
} as const;
