import { ApolloLink } from "@apollo/client";
import trimDecrypted from "core/model/encryption/trimDecrypted";
import { AnyObject } from "core/types";

const trimDecryptedLink = (trackingData: AnyObject) =>
  new ApolloLink((operation, forward) => {
    if (operation.variables.input != null) {
      const safeInput = trimDecrypted(operation.variables.input, {
        trackingData,
        context: {
          operation: operation.operationName,
          ...Object.fromEntries(
            Object.entries(operation.variables)
              .filter(([key]) => key !== "input")
              .map(([key, value]) => {
                return [`operationvar_${key}`, value];
              }),
          ),
        },
      });

      operation.variables.input = safeInput;
    }

    return forward(operation);
  });

export default trimDecryptedLink;
