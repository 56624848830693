import { FEDERAL_STATES, PROVIDER_SEARCH_BAVARIA } from "core/consts";
import { ActiveProviderSearch, ValueOf } from "core/types";

export type ProviderSearchConfig = {
  allowedFederalState: ValueOf<typeof FEDERAL_STATES>;
  logos: {
    default: string;
    favicon: string;
    loading: string;
    mobile: string;
  };
};

export const PROVIDER_SEARCH_CONFIG: Record<
  ActiveProviderSearch,
  ProviderSearchConfig
> = {
  [PROVIDER_SEARCH_BAVARIA]: {
    logos: {
      mobile: "bcp-logo-header-mobile@3x.png",
      default: "bcp-logo-header@3x.png",
      loading: "bcp-logo.png",
      favicon: "bcp-logo.ico",
    },
    allowedFederalState: FEDERAL_STATES.BAYERN,
  },
};
