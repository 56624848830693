import { Validation } from "core/types";

export const VALUE_CHANGE_EVENT_TYPE = "VALUE_CHANGE" as const;
export const INITIAL_CHANGE_EVENT_TYPE = "INITIAL_CHANGE" as const;
export const SUBMIT_EVENT_TYPE = "SUBMIT" as const;
export const VALIDATION_FAILED_EVENT_TYPE = "VALIDATION_FAILED" as const;

const EVENT_TYPES = [
  VALUE_CHANGE_EVENT_TYPE,
  INITIAL_CHANGE_EVENT_TYPE,
  SUBMIT_EVENT_TYPE,
  VALIDATION_FAILED_EVENT_TYPE,
] as const;

export type FormStateEvent = (typeof EVENT_TYPES)[number];

export function createValueChangeEvent(
  newValue: any,
  statePath: string,
  validation: Validation,
) {
  return {
    type: VALUE_CHANGE_EVENT_TYPE,
    newValue,
    statePath,
    validation,
  };
}

export function createInitialChangeEvent(newInitial: any) {
  return {
    type: INITIAL_CHANGE_EVENT_TYPE,
    newValue: newInitial,
  };
}

export function createSubmitChangeEvent() {
  return {
    type: SUBMIT_EVENT_TYPE,
  };
}

export function createValidationFailedEvent(validation: Validation) {
  return {
    type: VALIDATION_FAILED_EVENT_TYPE,
    validation,
  };
}
