import transport from "core/model/api/transport";
import { Careseeker } from "core/types";

export default {
  getByID(id: number): Promise<Careseeker> {
    const queryParams = {
      fields: "billing,providers",
    };

    return transport.get({
      route: `/careseekers/${id}`,
      queryParams,
      options: {
        routeName: "getCareseekerById",
      },
    });
  },
};
