import { isObject } from "core/model/objects";
import { isProd } from "core/model/utils/featureFlags";
import { BaseEventSchema } from "core/validationSchemas/tracking";
import validateTrackingEventData from "core/validationSchemas/tracking/validator";
import analytics from ".";

const DEFAULT_COLOR = "#006400";
const SYSTEM_EVENT_COLOR = "#1caabd";
const PROPERTY_COLOR = "#16A085";

function logProperties(obj: AnyObject) {
  const singleProps = [];
  for (const key in obj) {
    if (key === "name") continue;
    const value = obj[key as keyof typeof obj];
    if (isObject(value)) {
      console.groupCollapsed(`%c ${key}`, `color: ${PROPERTY_COLOR}`);
      logProperties(value);
      console.groupEnd();
    } else {
      singleProps.push([key, value]);
    }
  }
  singleProps.forEach(([key, value]) => {
    console.log(`${key}: ${value}`);
  });
}

export async function logTrackingInfo(
  event: BaseEventSchema | BaseEventSchema[],
): ReturnType<typeof analytics.track> {
  return new Promise((resolve) => {
    if (process.env.JEST_WORKER_ID !== undefined || isProd) resolve(null);

    const events = Array.isArray(event) ? event : [event];

    validateTrackingEventData({ events });

    events.forEach((event) => {
      const title = `Track Event: ${event.name} ${
        event.system_event ? `(System Event)` : ""
      }`;
      console.groupCollapsed(
        `%c ${title}`,
        `color: ${event.system_event ? SYSTEM_EVENT_COLOR : DEFAULT_COLOR}`,
      );
      logProperties(event);
      console.groupEnd();
    });
    resolve(null);
  });
}

export const mockedAnalytics: typeof analytics = {
  track: (event: BaseEventSchema | BaseEventSchema[]) => logTrackingInfo(event),
  getQuicksightLink: (id: number) =>
    new Promise((resolve) => resolve({ url: `mocked_url/${String(id)}` })),
};
