import { ApolloLink, fromPromise, Operation } from "@apollo/client";
import { TRACK_EVENTS } from "core/consts";
import { TrackEventFn } from "core/types";

const operationTimes = new Map();

const getOperationKey = (operation: Operation) =>
  `${operation.operationName}[${JSON.stringify({
    ...(operation.variables ?? {}),
    input: undefined,
  })}]`;

const requestTimerLink = new ApolloLink((operation, forward) => {
  const operationKey = getOperationKey(operation);
  if (!operationTimes.has(operationKey)) {
    operationTimes.set(operationKey, new Date().getTime());
  }
  return forward(operation);
});

const responseTimerLink = (trackEvent: TrackEventFn) =>
  new ApolloLink((operation, forward) => {
    return forward(operation).flatMap((data) =>
      fromPromise(
        new Promise((r) => {
          let contentLength = 0;
          const context = operation.getContext();
          const headers = context?.restResponses?.[0]?.headers;
          if (headers) {
            contentLength = Number(headers.get("Content-Length") || 0);
          }
          const operationKey = getOperationKey(operation);
          if (operationTimes.has(operationKey)) {
            const start = operationTimes.get(operationKey);
            const duration = new Date().getTime() - start;
            trackEvent({
              name: TRACK_EVENTS.OPERATION_TIMES,
              operation_name: operation.operationName,
              operation_key: operationKey,
              duration,
              content_length: contentLength,
              graphqlproxy: true,
            });

            operationTimes.delete(operationKey);
          }
          r(data);
        }),
      ),
    );
  });

export const operationTimesLinks = (trackEvent: TrackEventFn) => [
  requestTimerLink,
  responseTimerLink(trackEvent),
];
