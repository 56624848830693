import { AnyObject } from "core/types";
import { fromJS, Iterable } from "immutable";
import { getImmutPath } from "./StatePathHelpers";
import { Whitelist } from "./types";

export function getJavascriptEntity(obj: AnyObject | Iterable<any, any>) {
  if (obj && typeof obj === "object" && obj.toJS) {
    return obj.toJS();
  }
  return obj;
}

export function getValue<S extends AnyObject = AnyObject>(
  state: S,
  path: string,
) {
  if (path === "") {
    return getJavascriptEntity(state);
  }
  if (typeof state === "object") {
    const valuePath = getImmutPath(path);
    return valuePath.reduce((red: any, value: any) => {
      if (red !== undefined && red !== null) {
        return red[value];
      }
      return red;
    }, state);
  }
  return state;
}

export function setValue<S = AnyObject>(state: S, path: string, value: any) {
  if (path === "") {
    return value;
  }
  const immutState = fromJS(state);

  return immutState
    ? immutState.setIn(getImmutPath(path), value).toJS()
    : immutState;
}

export function hideComponent(whitelist: Whitelist | null, statePath: string) {
  if (!whitelist) return null;

  const value = whitelist[statePath];
  if (value == null) return null;

  return value == false;
}
