export const magenta = {
  50: "#FCEDFB",
  100: "#F7CAF2",
  200: "#F4B8ED",
  300: "#EE95E4",
  400: "#E972DB",
  500: "#E34FD2",
  600: "#C920B5",
  700: "#A61A95",
  800: "#7C136F",
  900: "#470B40",
} as const;
