import Paper from "@mui/material/Paper";
import { CSSTextAlign, TooltipPlacement } from "core/types";
import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_DARK } from "ds_legacy/materials/colors";
import { border } from "ds_legacy/materials/metrics";
import { ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";

type CirclePositionerProps = {
  bottom?: CSSProperties["bottom"];
  left?: CSSProperties["left"];
  position?: CSSProperties["position"];
  right?: CSSProperties["right"];
  top?: CSSProperties["top"];
  zIndex?: CSSProperties["zIndex"];
};
export type CircleProps = CirclePositionerProps & {
  children?: ReactNode;
  color?: string;
  diameter?: number;
  elevation?: number;
  tooltipLabel?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipTextAlign?: CSSTextAlign;
};

const CirclePositioner = styled.div<CirclePositionerProps>`
  justify-content: center;
  display: flex;
  ${({ position }) => position != null && `position: ${position}`};
  ${({ top }) => top != null && `top: ${top}`};
  ${({ left }) => left != null && `left: ${left}`};
  ${({ bottom }) => bottom != null && `bottom: ${bottom}`};
  ${({ right }) => right != null && `right: ${right}`};
  ${({ zIndex }) => zIndex != null && `z-index: ${zIndex}`};
`;

const style: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  overflow: "hidden",
  borderRadius: "50%",
  cursor: "default",
};

function MuiCircle({
  children,
  color,
  diameter,
  elevation,
  ...props
}: {
  children?: ReactNode;
  color?: string;
  diameter?: number;
  elevation?: number;
}) {
  return (
    <Paper
      style={{
        ...style,
        backgroundColor: color,
        height: diameter || 8,
        width: diameter || 8,
        border: border({ color: color || ICON_DARK }),
      }}
      elevation={elevation || 0}
      {...props}
    >
      {children}
    </Paper>
  );
}

export function Circle({
  bottom,
  children,
  color,
  diameter,
  elevation,
  left,
  position,
  right,
  tooltipLabel,
  tooltipPlacement,
  tooltipTextAlign,
  top,
  zIndex,
  ...props
}: CircleProps) {
  const content = (
    <MuiCircle
      diameter={diameter}
      color={color}
      elevation={elevation}
      {...props}
    >
      {children}
    </MuiCircle>
  );

  return tooltipLabel ? (
    <Tooltip
      title={tooltipLabel}
      placement={tooltipPlacement}
      tooltipTextAlign={tooltipTextAlign}
    >
      <CirclePositioner
        position={position}
        top={top}
        left={left}
        bottom={bottom}
        right={right}
        zIndex={zIndex}
      >
        {content}
      </CirclePositioner>
    </Tooltip>
  ) : (
    <CirclePositioner
      position={position}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      zIndex={zIndex}
    >
      {content}
    </CirclePositioner>
  );
}
