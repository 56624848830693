import transport from "core/model/api/transport";

export default {
  registerDeviceToken({
    accountId,
    deviceId,
    deviceToken,
  }: {
    accountId: number;
    deviceId: string;
    deviceToken: string;
  }): Promise<any> {
    return transport.put({
      route: `/accounts/${accountId}/push/register`,
      body: {
        device_id: deviceId,
        device_token: deviceToken,
      },
    });
  },
  unregisterDeviceToken({
    accountId,
    deviceId,
    deviceToken,
  }: {
    accountId: number;
    deviceId: string;
    deviceToken: string;
  }): Promise<any> {
    return transport.put({
      route: `/accounts/${accountId}/push/unregister`,
      body: {
        device_id: deviceId,
        device_token: deviceToken,
      },
    });
  },
};
