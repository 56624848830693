import { Theme, lighten } from "@mui/material/styles";
import { PaletteColor } from "@mui/material/styles/createPalette";
import makeStyles from "@mui/styles/makeStyles";
import { ValueOf } from "core/types";
import { CUSTOM_BLACK, WHITE } from "ds_legacy/materials/colors";
import {
  border,
  dp,
  important,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { InfoBannerProps } from ".";

export const SEVERITY_THEME_MAP = {
  success: "success",
  error: "error",
  warning: "secondary",
  info: "primary",
} as const;

const getActionsColor = ({
  palette,
  paletteVariant,
  variant,
}: {
  palette: PaletteColor;
  paletteVariant: ValueOf<typeof SEVERITY_THEME_MAP>;
  variant: InfoBannerProps["variant"];
}) => {
  if (variant !== "filled") {
    return palette.dark;
  }
  if (paletteVariant === "secondary") {
    return CUSTOM_BLACK;
  }
  return WHITE;
};

const getStandardStyle = ({
  highContrast,
  palette,
  theme,
}: {
  highContrast: InfoBannerProps["highContrast"];
  palette: PaletteColor;
  theme: Theme;
}) => {
  if (highContrast) {
    // this is suboptimal but we use these banners everywhere (e.g. search merge) and we need a contrastly solution for e.g. the refresh banner for BCP without breaking all apps
    return {
      backgroundColor: important(lighten(palette.main, 0.88)), // match the 0.12 lighten mui does by default in active components eg pagination
      border: border({ color: palette.main, important: true }),
      "& .MuiAlert-icon": {
        color: important(theme.palette.common.black),
      },
    };
  }

  return {
    backgroundColor: important(palette.light),
    "& .MuiAlert-icon": {
      color: important(palette.dark),
    },
  };
};

export const useStyles = ({
  highContrast,
  messageMaxWidth,
  noIcon,
  severity = "info",
  variant,
}: {
  highContrast: InfoBannerProps["highContrast"];
  messageMaxWidth: InfoBannerProps["messageMaxWidth"];
  noIcon: InfoBannerProps["noIcon"];
  severity: InfoBannerProps["severity"];
  variant: InfoBannerProps["variant"];
}) =>
  makeStyles((theme) => {
    const paletteVariant = SEVERITY_THEME_MAP[severity];
    const palette = theme.palette[paletteVariant];
    const actionsColor = getActionsColor({
      palette,
      paletteVariant,
      variant,
    });
    const standardStyle = getStandardStyle({ palette, theme, highContrast });

    return {
      verticalDivider: {
        background: actionsColor,
        width: dp(1),
        height: "95%",
        marginRight: sizing(1),
      },
      message: {
        maxWidth: messageMaxWidth,
        color: CUSTOM_BLACK,
        padding: important(padding(1, 1, 1, 0)),
      },
      actionButton: {
        color: important(
          highContrast ? theme.palette.common.black : actionsColor,
        ),
        margin: margin(0),
      },
      closeIcon: {
        color: actionsColor,
      },
      filledCloseIcon: {
        color: WHITE,
      },
      icon: {
        display: noIcon ? important("none") : undefined,
      },
      standard: standardStyle,
      outlined: {
        borderColor: important(palette.main),
        "& .MuiAlert-icon": {
          color: important(palette.main),
        },
      },
      filled: {
        backgroundColor: important(palette.main),
        borderColor: important(palette.main),
        "& .MuiAlert-icon": {
          color: WHITE,
        },
        "& .MuiAlert-message": {
          color: WHITE,
        },
        "& .MuiAlertTitle-root": {
          color: WHITE,
        },
      },
      filledWarning: {
        "& .MuiAlert-message": {
          color: CUSTOM_BLACK,
        },
        "& .MuiAlert-icon": {
          color: CUSTOM_BLACK,
        },
        "& .MuiAlertTitle-root": {
          color: CUSTOM_BLACK,
        },
      },
      action: {
        padding: important(padding(0)),
      },
    };
  });
