import { EncryptionSession } from "@seald-io/sdk/lib/main";
import { TRACK_EVENTS } from "core/consts";
import {
  decryptMessage,
  getEncryptedMessage,
  getSessionAccess,
} from "core/model/crypto/cryptoService";
import trimDecrypted from "core/model/encryption/trimDecrypted";
import { getSealdSDKInstance } from "core/seald";
import { decryptFromSession, encryptForSession } from "core/seald/sessions";
import {
  ApolloEncryptionContext,
  EncryptedField,
  EncryptionContext,
  IsSealdOnly,
  TrackEventFn,
} from "core/types";
import { differenceInMilliseconds } from "date-fns";
import "indexeddb-getall-shim";
import cloneDeep from "lodash/cloneDeep";
import {
  getHashCache,
  getSessionCache,
  setHashCache,
  setSessionCache,
} from "./caches";
import { Typenames } from "./modelDefinition";
import { EncryptionDefinition, Keys, KeysForSession } from "./queryKeys";

type Data = AnyObject;
export type PatchMode = "decrypt" | "encrypt";

const normalizedTypenames: AnyObject = Object.entries(Typenames).reduce(
  (acc, cur) => {
    const [typename, [singular, plural]] = cur;
    return { ...acc, [singular]: typename, [plural]: typename };
  },
  {},
);

function getTypename(key: string) {
  return normalizedTypenames[key];
}

async function decryptFieldSeald(
  currentKey: string,
  { seald_content }: EncryptedField,
  sealdSession: EncryptionSession | undefined,
): Promise<[string, "cache" | "decrypted"] | undefined> {
  if (!sealdSession) return undefined;
  if (seald_content == "redacted") return undefined;

  try {
    const decrypted = await decryptFromSession({
      encryptedMessage: seald_content,
      session: sealdSession,
      context: `patcher field ${currentKey}`,
    });
    if (!decrypted) return undefined;
    return [decrypted, "decrypted"];
  } catch (decryptError) {
    console.error(
      `Failed to decrypt field w/seald [${currentKey}] :`,
      decryptError,
    );
  }

  return undefined;
}

async function decryptFieldOld(
  currentKey: string,
  { content, iv }: EncryptedField,
  decryptedSessionKey: CryptoKey,
): Promise<[string, "cache" | "decrypted"] | undefined> {
  if (content == null || !iv) return undefined;
  if (content == "redacted") return undefined;

  try {
    const cachedValue = getHashCache(content);
    if (cachedValue) {
      return [cachedValue, "cache"];
    }

    const decrypted = await decryptMessage({
      message: content,
      sessionKey: decryptedSessionKey,
      iv,
    });
    if (decrypted != null) setHashCache(content, decrypted);

    return [decrypted, "decrypted"];
  } catch (decryptError) {
    console.error(`Failed to decrypt field [${currentKey}] :`, decryptError);
  }

  return undefined;
}

async function decryptField(
  currentKey: string,
  encryptedField: EncryptedField,
  decryptedSessionKey: CryptoKey,
  sealdSession: EncryptionSession | undefined,
  isSealdOnly: IsSealdOnly,
): Promise<[string, "cache" | "decrypted"] | undefined> {
  if (
    isSealdOnly({
      oldSession: decryptedSessionKey,
      newSealdSession: sealdSession,
    }) &&
    // [DEV-14586] if there is only old content and no seald content
    // even with a seald session, fallback to the old encryption
    !(encryptedField.content && !encryptedField.seald_content)
  ) {
    return sealdSession
      ? await decryptFieldSeald(currentKey, encryptedField, sealdSession)
      : undefined;
  }

  return decryptedSessionKey
    ? await decryptFieldOld(currentKey, encryptedField, decryptedSessionKey)
    : undefined;
}

async function encryptField(
  currentKey: string,
  { decrypted }: EncryptedField,
  decryptedSessionKey: CryptoKey,
  sealdSession: EncryptionSession | undefined,
  isSealdOnly: IsSealdOnly,
) {
  if (decrypted == null) return undefined;

  try {
    if (decrypted != null) {
      let content, iv;
      if (
        !isSealdOnly({
          oldSession: decryptedSessionKey,
          newSealdSession: sealdSession,
        })
      ) {
        const encryptedMessageResult = await getEncryptedMessage(
          decrypted,
          decryptedSessionKey,
        );
        content = encryptedMessageResult.encryptedMessage;
        iv = encryptedMessageResult.message_iv;
        setHashCache(content, decrypted);

        return { content, iv };
      }

      // seald encrypt
      const seald_content = await encryptForSession({
        message: decrypted,
        session: sealdSession,
        context: `patcher field ${currentKey}`,
      });

      return { seald_content };
    }
  } catch (encryptError) {
    console.error(`Failed to encrypt field [${currentKey}] :`, encryptError);
  }

  return undefined;
}

function getAttribute(
  previousKey: string,
  currentData: any,
): [number, string, string] {
  const attributeId = currentData.id as number;
  const typename = getTypename(previousKey);
  const attributeName: string = typename
    .split(/(?=[A-Z])/)
    .concat("id")
    .map((s: string) => s.toLowerCase())
    .join("_");
  const attributeKey = `${attributeName}_${attributeId}`;
  return [attributeId, attributeName, attributeKey];
}

async function getDecryptedSessionKey({
  attributeId,
  attributeKey,
  attributeName,
  patientSessionKey,
  privateKey,
  trackEvent,
}: {
  attributeId: number;
  attributeKey: string;
  attributeName: string;
  patientSessionKey: any;
  privateKey: string;
  trackEvent: TrackEventFn;
}) {
  const encryptedSessionKey = patientSessionKey.session_key;
  const cachedDecryptedSessionKey = getSessionCache(
    attributeKey,
    encryptedSessionKey,
  );

  if (cachedDecryptedSessionKey) {
    return [cachedDecryptedSessionKey, "cache"];
  }

  const decryptedSessionKey = await getSessionAccess({
    context: { [attributeName]: attributeId },
    privateKey,
    sessionKey: patientSessionKey,
    trackEvent,
  });

  if (decryptedSessionKey != null) {
    setSessionCache(attributeKey, encryptedSessionKey, decryptedSessionKey);
  }

  return [decryptedSessionKey, "decrypted"];
}

async function getCurrentSealdSession(
  sealdEncryptionContext: EncryptionContext | null | undefined,
) {
  const currentSealdSessionId = sealdEncryptionContext?.seald_id;
  if (!currentSealdSessionId) return null;
  let currentSealdSession: EncryptionSession | null = null;
  try {
    const sealdSDKInstance = await getSealdSDKInstance();
    if (sealdSDKInstance)
      currentSealdSession = await sealdSDKInstance?.retrieveEncryptionSession({
        sessionId: currentSealdSessionId,
      });
  } catch (error) {
    console.error("Seald error getting session in patcher", error);
  }
  return currentSealdSession;
}

type MutateEncryptionContext = {
  privateKey: string;
  trackEvent: TrackEventFn;
};

type MutateProps = {
  currentData: Data;
  debugAttributeKey?: string;
  decryptCurrentLevel?: boolean;
  encryptionDefinition: AnyObject;
  integrationMode: boolean;
  isSealdOnly: IsSealdOnly;
  keys: Keys;
  keysForSession: KeysForSession;
  mode: PatchMode;
  mutateEncryptionContext: MutateEncryptionContext;
  originalDebugData: any;
  previousKey: string;
  sealdSession: EncryptionSession | undefined;
  sessionKey: any;
};

async function mutate({
  currentData,
  debugAttributeKey = "",
  decryptCurrentLevel,
  encryptionDefinition,
  integrationMode,
  isSealdOnly,
  keys,
  keysForSession,
  mode,
  mutateEncryptionContext,
  originalDebugData,
  previousKey,
  sealdSession,
  sessionKey,
}: MutateProps): Promise<void> {
  let currentDebugAttributeKey = debugAttributeKey;
  let decryptedSessionKey: any;
  let currentSealdSession: EncryptionSession | null | undefined;

  const currentKey = keys[0];

  // keysForSession === [ [a1, b1, c1], [a2, b2], [a3], [a4] ]
  // currentPossibleKeysForSession ==== [a3, a4]
  // nextKeysForSession === [ [b1, c1], [b2] ]
  // nextPossibleKeysForSession ==== [b2]
  const currentPossibleKeysForSession = keysForSession.reduce(
    (accumulator, current) =>
      current.length === 1 ? accumulator.concat(current[0]) : accumulator,
    [],
  );
  const nextKeysForSession = keysForSession.map((keyForSessionPathArray) =>
    keyForSessionPathArray.slice(1),
  );
  // we need to decrypt on the next iteration of the fn instead of when
  // we find the key, because it can be an array
  // we only want to decryptNext level if we transverse to that node and
  // it's the correct key node
  // e.g. for {x, y, z} in currentData and [y, z] in currentPossibleKeysForSession
  // if the nextKex is x send false
  // if the nextKex is y or z send true
  // if no data transversal (currentKey is array) send previous one (decryptCurrentLevel)
  const decryptNextLevel =
    typeof currentKey === "string" &&
    currentPossibleKeysForSession.includes(currentKey);
  // currentKeyForSession should be falsy if there's no session key to decrypt
  // at the current level
  if (decryptCurrentLevel && currentData && !Array.isArray(currentData)) {
    let attributeProps;
    try {
      attributeProps = getAttribute(previousKey, currentData);
    } catch (err) {
      console.error(`
      DecryptPatcher error
        currentData
          Keys:
            ${JSON.stringify({ keys }, null, 2)}
          Original data:
            ${JSON.stringify({ ...originalDebugData }, null, 2)}
        Error: ${err}
      `);
      return;
    }
    const [attributeId, attributeName, attributeKey] = attributeProps;
    currentDebugAttributeKey = attributeKey;

    const patientSessionKey = currentData.session_key_context?.session_key;
    const sealdEncryptionContext = currentData.seald_encryption_context;
    if (patientSessionKey != null || sealdEncryptionContext != null) {
      if (
        // [DEV-14586] allow the old session key to still be decrypted if it exists with also the seald session here
        // to fix the bug with some accounts that had issues in the encryption transition and have
        // patients with a seald session but without seald_content in the fields
        // !isSealdOnly({
        //   oldSession: patientSessionKey,
        //   newSealdSession: sealdEncryptionContext?.seald_id,
        // }) &&
        patientSessionKey != null
      ) {
        const [decryptedSessionKeyResult, decryptionType] =
          await getDecryptedSessionKey({
            patientSessionKey,
            privateKey: mutateEncryptionContext.privateKey,
            trackEvent: mutateEncryptionContext.trackEvent,
            attributeId,
            attributeName,
            attributeKey,
          });
        decryptedSessionKey = decryptedSessionKeyResult;

        const decryptionIdentifier =
          "_decryption_id_" + attributeName + attributeId;
        encryptionDefinition[`session_key_context${decryptionIdentifier}`] =
          decryptionType;
      }
      if (sealdEncryptionContext != null) {
        currentSealdSession = await getCurrentSealdSession(
          currentData.seald_encryption_context,
        );
      }
      // when decrypting, the session keys should always be in the data
      // when encrypting, they'll likely be in cache
      // unless PUTing without GETing first (e.g. right after POSTing)
      // additionally, when ecrypting , you might not have the patientSessionKey in the input data
      // ence this condition
    } else if (mode === "encrypt") {
      decryptedSessionKey = getSessionCache(attributeKey);
      currentSealdSession = await getCurrentSealdSession(
        currentData.seald_encryption_context,
      );
    } else if (currentData.session_key_context?.has_session_keys === true) {
      return;
    }
  }

  if (
    keys.length === 1 &&
    !Array.isArray(keys[0]) &&
    !Array.isArray(currentKey) &&
    currentData &&
    !Array.isArray(currentData)
  ) {
    if (
      currentData != null &&
      typeof currentData === "object" &&
      currentKey in currentData &&
      currentData[currentKey] != null &&
      typeof currentData[currentKey] === "object"
    ) {
      if (
        sessionKey != null ||
        decryptedSessionKey != null ||
        sealdSession != null ||
        currentSealdSession != null
      ) {
        if (mode === "decrypt") {
          const decryptedField = await decryptField(
            currentKey,
            currentData[currentKey],
            decryptedSessionKey ?? sessionKey,
            currentSealdSession ?? sealdSession,
            isSealdOnly,
          );
          if (decryptedField) {
            const [decryptedData, decryptionType] = decryptedField;
            // eslint-disable-next-line require-atomic-updates
            const decryptionIdentifier =
              "_decryption_id_" + currentData[currentKey].content;
            encryptionDefinition[`${currentKey}${decryptionIdentifier}`] =
              decryptionType;
            if (integrationMode) {
              currentData[currentKey] = decryptedData;
            } else {
              // eslint-disable-next-line require-atomic-updates
              currentData[currentKey].decrypted = decryptedData;
            }
          }
        } else if (mode === "encrypt") {
          // eslint-disable-next-line require-atomic-updates
          currentData[currentKey] = await encryptField(
            currentKey,
            currentData[currentKey],
            decryptedSessionKey ?? sessionKey,
            currentSealdSession ?? sealdSession,
            isSealdOnly,
          );
        }
      }
    }
    return;
  }

  // e.g. currentData = [{dataWithEncryptedField_id_1, dataWithEncryptedField_id_1}]
  if (Array.isArray(currentData)) {
    await Promise.all(
      currentData.map((nextData: Data) =>
        mutate({
          originalDebugData,
          mode,
          currentData: nextData,
          encryptionDefinition,
          keys,
          keysForSession,
          previousKey,
          mutateEncryptionContext,
          sessionKey: decryptedSessionKey ?? sessionKey,
          sealdSession: currentSealdSession ?? sealdSession,
          decryptCurrentLevel,
          debugAttributeKey: currentDebugAttributeKey,
          integrationMode,
          isSealdOnly,
        }),
      ),
    );
    return;
  }

  if (Array.isArray(currentKey)) {
    // if for example ["financing", ["insurance", ["number"]]]
    // it should get it in this "if", entering the "financing" key
    if (
      !Array.isArray(currentKey[0]) &&
      currentKey.some(Array.isArray) &&
      currentData != null &&
      typeof currentData === "object" &&
      currentKey[0] in currentData &&
      currentData[currentKey[0]] != null
    ) {
      const decryptNextLevelForCurrentKeyArray =
        currentPossibleKeysForSession.includes(currentKey[0]);
      await mutate({
        originalDebugData,
        mode,
        currentData: currentData[currentKey[0]],
        encryptionDefinition: encryptionDefinition[currentKey[0]],
        keys: currentKey.slice(1),
        keysForSession: nextKeysForSession,
        previousKey: currentKey[0],
        mutateEncryptionContext,
        sessionKey: decryptedSessionKey ?? sessionKey,
        sealdSession: currentSealdSession ?? sealdSession,
        decryptCurrentLevel: decryptNextLevelForCurrentKeyArray,
        debugAttributeKey: currentDebugAttributeKey,
        integrationMode,
        isSealdOnly,
      });
      return;
    }

    // otherwise either all arrays [[], []]
    // or all strings ["first_name", "last_name"]
    // it should split
    await Promise.all(
      currentKey.map((nextKey) => {
        return mutate({
          originalDebugData,
          mode,
          currentData,
          encryptionDefinition,
          keys: [nextKey],
          keysForSession,
          previousKey,
          mutateEncryptionContext,
          sessionKey: decryptedSessionKey ?? sessionKey,
          sealdSession: currentSealdSession ?? sealdSession,
          decryptCurrentLevel,
          debugAttributeKey: currentDebugAttributeKey,
          integrationMode,
          isSealdOnly,
        });
      }),
    );
    return;
  }

  if (currentData && Array.isArray(currentData[currentKey])) {
    await Promise.all(
      currentData[currentKey].map((nextData: Data) =>
        mutate({
          originalDebugData,
          mode,
          currentData: nextData,
          encryptionDefinition: encryptionDefinition[currentKey],
          keys: keys.slice(1),
          keysForSession: nextKeysForSession,
          previousKey: currentKey,
          mutateEncryptionContext,
          sessionKey: decryptedSessionKey ?? sessionKey,
          sealdSession: currentSealdSession ?? sealdSession,
          decryptCurrentLevel: decryptNextLevel,
          debugAttributeKey: currentDebugAttributeKey,
          integrationMode,
          isSealdOnly,
        }),
      ),
    );
    return;
  }

  if (currentData) {
    await mutate({
      originalDebugData,
      mode,
      currentData: currentData[currentKey],
      encryptionDefinition: encryptionDefinition[currentKey],
      keys: keys.slice(1),
      keysForSession: nextKeysForSession,
      previousKey: currentKey,
      mutateEncryptionContext,
      sessionKey: decryptedSessionKey ?? sessionKey,
      sealdSession: currentSealdSession ?? sealdSession,
      decryptCurrentLevel: decryptNextLevel,
      debugAttributeKey: currentDebugAttributeKey,
      integrationMode,
      isSealdOnly,
    });
  }
  return;
}

function countDecryptedFields(
  currentData: AnyObject,
  sumFieldCache = 0,
  sumFieldDecrypt = 0,
  sumKeyCache = 0,
  sumKeyDecrypt = 0,
) {
  const nextFields = Object.keys(currentData).filter(
    (field) => typeof currentData[field] === "object",
  );

  const currentFields = Object.keys(currentData).filter(
    (field) =>
      typeof currentData[field] !== "object" &&
      field.includes("_decryption_id_"),
  );
  if (nextFields.length) {
    nextFields.forEach((field) => {
      const [
        fieldSumFieldCache,
        fieldSumFieldDecrypt,
        fieldSumKeyCache,
        fieldSumKeyDecrypt,
      ] = countDecryptedFields(
        currentData[field],
        sumFieldCache,
        sumFieldDecrypt,
        sumKeyCache,
        sumKeyDecrypt,
      );
      sumFieldCache = sumFieldCache + (fieldSumFieldCache - sumFieldCache);
      sumFieldDecrypt =
        sumFieldDecrypt + (fieldSumFieldDecrypt - sumFieldDecrypt);

      sumKeyCache = sumKeyCache + (fieldSumKeyCache - sumKeyCache);
      sumKeyDecrypt = sumKeyDecrypt + (fieldSumKeyDecrypt - sumKeyDecrypt);
    });
  }

  if (currentFields.length) {
    currentFields.forEach((field) => {
      if (field.includes("session_key_context")) {
        if (currentData[field] === "cache") sumKeyCache++;
        else if (currentData[field] === "decrypted") sumKeyDecrypt++;
      } else {
        if (currentData[field] === "cache") sumFieldCache++;
        else if (currentData[field] === "decrypted") sumFieldDecrypt++;
      }
    });
  }

  return [sumFieldCache, sumFieldDecrypt, sumKeyCache, sumKeyDecrypt];
}
export default async function decryptPatcher({
  data,
  encryptionContext,
  encryptionDefinition,
  integrationMode,
  mode,
}: {
  data: Data;
  encryptionContext: ApolloEncryptionContext;
  encryptionDefinition: EncryptionDefinition;
  integrationMode?: boolean;
  mode: PatchMode;
}): Promise<Data> {
  const { isSealdOnly, privateKey, trackEvent } = encryptionContext;

  if (!privateKey) return data;

  const mutateEncryptionContext = { trackEvent, privateKey };

  const {
    encryptedFields,
    keysForSession,
    modelDefinition: originalModelDefinition,
    operationName,
  } = encryptionDefinition;

  const modelDefinitionCopy = cloneDeep(originalModelDefinition);

  // in case of encryption, the data(input) doesn't have the root
  // key, so we need to add it and then stripe it down
  const rootKey = Object.keys(modelDefinitionCopy)[0];

  data = (() => {
    if (mode === "encrypt") {
      // in case of encrypt, the data is the query input that comes
      // from the apollo mutation. for that reason, is is not safe
      // to mutate the object directly
      const input = cloneDeep(data);
      return { [rootKey]: input };
    }
    // in case of decrypt the data comes from the request, so it's
    // safe to modify it before passing it down
    return data;
  })();

  const originalDebugData = {
    mode,
    encryptedFields,
    data: mode === "encrypt" ? trimDecrypted(cloneDeep(data)) : cloneDeep(data),
  };

  try {
    const start = new Date();
    await mutate({
      originalDebugData,
      mode,
      currentData: data,
      encryptionDefinition: modelDefinitionCopy,
      keys: encryptedFields,
      keysForSession,
      previousKey: "",
      mutateEncryptionContext,
      sessionKey: undefined,
      sealdSession: undefined,
      integrationMode: !!integrationMode,
      isSealdOnly,
    });

    if (mode === "decrypt") {
      console.log(
        "%c Decrypted model ",
        "background: #222; color: #bada55",
        modelDefinitionCopy,
      );
    }

    const duration = differenceInMilliseconds(new Date(), start);
    const [fieldsInCache, fieldsDecrypted, keysInCache, keysDecrypted] =
      countDecryptedFields(modelDefinitionCopy);

    let trackContext: AnyObject = {
      duration,
      mode,
      operation_name: operationName,
    };

    if (mode === "decrypt") {
      trackContext = {
        ...trackContext,
        fields_in_cache: fieldsInCache,
        fields_decrypted: fieldsDecrypted,
        keys_in_cache: keysInCache,
        keys_decrypted: keysDecrypted,
      };
    }
    trackEvent({
      name: TRACK_EVENTS.DECRYPT_PATCHER,
      system_event: true,
      ...trackContext,
    });
  } catch (err) {
    console.error("DecryptPatcher error: ", err);
  }

  if (mode === "encrypt") {
    return data[rootKey];
  }
  return data;
}
