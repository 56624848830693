import { AnyObject } from "core/types";
import { initEncryptionCaches } from "./caches";
import decryptionLink from "./decryptionLink";
import encryptionLink from "./encryptionLink";
import trimDecryptedLink from "./trimDecryptedLink";

initEncryptionCaches();

const cryptoLinks = (trackingData: AnyObject) => [
  encryptionLink,
  decryptionLink,
  trimDecryptedLink(trackingData),
];

export default cryptoLinks;
