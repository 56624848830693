export const yellow = {
  50: "#FFFFDB",
  100: "#FFFFB4",
  200: "#FFFF70",
  300: "#FFEC70",
  400: "#FFE121",
  500: "#FFCC00",
  600: "#E0BC08",
  700: "#C5A507",
  800: "#8B7503",
  900: "#625304",
} as const;
