import {
  INDEX_DB_SESSION_KEYS,
  INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
  INDEX_DB_SESSION_KEYS_HASHES_TABLE,
  INDEX_DB_SESSION_KEYS_VERSION,
} from "core/consts";
import { callIndexedDB, initIndexedDB } from "core/model/utils/IndexedDB";

type HashCacheType = { [key: string]: string };
type AttributesToHashes = { [attributeKey: string]: number };
type HashesToKeys = { [hash: number]: CryptoKey };

function generateUnsecureHash(content: string) {
  let hash = 0;
  if (content.length == 0) {
    return hash;
  }
  for (let i = 0; i < content.length; i++) {
    const char = content.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

/*
.  _  _   _   ___ _  _    ___   _   ___ _  _ ___ 
. | || | /_\ / __| || |  / __| /_\ / __| || | __|
. | __ |/ _ \\__ \ __ | | (__ / _ \ (__| __ | _| 
. |_||_/_/ \_\___/_||_|  \___/_/ \_\___|_||_|___|
.                        
 */
let hashCache: HashCacheType = {};

function initHashCache() {
  try {
    const localMap = window.localStorage.getItem("hashCache");
    if (localMap != null) hashCache = JSON.parse(localMap);
  } catch (hashCacheError) {
    console.error("Failed to init hash cache: ", hashCacheError);
  }
}

export function getHashCache(encryptedContent: string): string {
  const encryptedContentHash = generateUnsecureHash(encryptedContent);
  const decryptedContent = hashCache[encryptedContentHash];
  return decryptedContent;
}

export function setHashCache(
  encryptedContent: string,
  decryptedContent: string,
) {
  try {
    const encryptedContentHash = generateUnsecureHash(encryptedContent);
    if (decryptedContent != null) {
      hashCache[encryptedContentHash] = decryptedContent;
      window.localStorage.setItem("hashCache", JSON.stringify(hashCache));
    }
  } catch (setHashCacheError) {
    console.log("Failed to set hash cache: ", setHashCacheError);
  }
}

function clearHashCache() {
  try {
    hashCache = {};
    window.localStorage.setItem("hashCache", JSON.stringify(hashCache));
  } catch (clearHashCacheError) {
    console.log("Failed to clear hash cache: ", clearHashCacheError);
  }
}

/*
.                          _                                  .-.         
.                         :_;                                 : :         
.  .--.  .--.  .--.  .--. .-. .--. ,-.,-.   .--.  .--.   .--. : `-.  .--. 
. `._-.'' '_.'`._-.'`._-.': :' .; :: ,. :  '  ..'' .; ; '  ..': .. :' '_.'
. `.__.'`.__.'`.__.'`.__.':_;`.__.':_;:_;  `.__.'`.__,_;`.__.':_;:_;`.__.'
.
 */
const sessionCache: {
  attributesToHashes: AttributesToHashes;
  hashesToKeys: HashesToKeys;
} = { hashesToKeys: {}, attributesToHashes: {} };

function initSessionCache() {
  try {
    initIndexedDB(
      INDEX_DB_SESSION_KEYS,
      [
        INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
        INDEX_DB_SESSION_KEYS_HASHES_TABLE,
      ],
      INDEX_DB_SESSION_KEYS_VERSION,
    );

    callIndexedDB(
      INDEX_DB_SESSION_KEYS,
      INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
      INDEX_DB_SESSION_KEYS_VERSION,
      function (store: IDBObjectStore) {
        const getData = store.getAll();
        getData.onsuccess = function () {
          const result = getData.result;
          sessionCache.attributesToHashes = result.reduce(
            (
              acc: AttributesToHashes,
              cur: { encryptedSessionKeyHash: string; id: string },
            ) => ({
              ...acc,
              [cur.id]: cur.encryptedSessionKeyHash,
            }),
            {},
          );
          callIndexedDB(
            INDEX_DB_SESSION_KEYS,
            INDEX_DB_SESSION_KEYS_HASHES_TABLE,
            INDEX_DB_SESSION_KEYS_VERSION,
            function (store: IDBObjectStore) {
              const getData = store.getAll();
              getData.onsuccess = function () {
                const result = getData.result;
                sessionCache.hashesToKeys = result.reduce(
                  (
                    acc: HashesToKeys,
                    cur: { decryptedSessionKey: CryptoKey; id: number },
                  ) => ({
                    ...acc,
                    [cur.id]: cur.decryptedSessionKey,
                  }),
                  {},
                );
              };
            },
            "init",
          );
        };
      },
      "init",
    );
  } catch (sessionCacheError) {
    console.error("Failed to init session cache: ", sessionCacheError);
  }
}

export function getSessionCache(
  attributeKey: string,
  encryptedSessionKey?: string,
) {
  const oldEcryptedSessionKeyHash =
    sessionCache.attributesToHashes[attributeKey];

  if (!oldEcryptedSessionKeyHash) return undefined;

  if (!encryptedSessionKey)
    return sessionCache.hashesToKeys[oldEcryptedSessionKeyHash];

  const newEcryptedSessionKeyHash = generateUnsecureHash(encryptedSessionKey);
  if (oldEcryptedSessionKeyHash === newEcryptedSessionKeyHash)
    return sessionCache.hashesToKeys[oldEcryptedSessionKeyHash];

  return undefined;
}

export function setSessionCache(
  attributeKey: string,
  encryptedSessionKey: string,
  decryptedSessionKey: CryptoKey,
) {
  try {
    const encryptedSessionKeyHash = generateUnsecureHash(encryptedSessionKey);

    callIndexedDB(
      INDEX_DB_SESSION_KEYS,
      INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
      INDEX_DB_SESSION_KEYS_VERSION,
      function (store: IDBObjectStore) {
        store.put({ id: attributeKey, encryptedSessionKeyHash });
      },
      "set",
    );
    sessionCache.attributesToHashes[attributeKey] = encryptedSessionKeyHash;

    callIndexedDB(
      INDEX_DB_SESSION_KEYS,
      INDEX_DB_SESSION_KEYS_HASHES_TABLE,
      INDEX_DB_SESSION_KEYS_VERSION,
      function (store: IDBObjectStore) {
        store.put({ id: encryptedSessionKeyHash, decryptedSessionKey });
      },
      "set",
    );
    sessionCache.hashesToKeys[encryptedSessionKeyHash] = decryptedSessionKey;
  } catch (setSessionKeyInCacheError) {
    console.log("Failed to set session cache: ", setSessionKeyInCacheError);
  }
}

function clearSessionCache() {
  try {
    callIndexedDB(
      INDEX_DB_SESSION_KEYS,
      INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
      INDEX_DB_SESSION_KEYS_VERSION,
      function (store: IDBObjectStore) {
        store.clear();
      },
      "clear",
    );
    sessionCache.attributesToHashes = {};

    callIndexedDB(
      INDEX_DB_SESSION_KEYS,
      INDEX_DB_SESSION_KEYS_HASHES_TABLE,
      INDEX_DB_SESSION_KEYS_VERSION,
      function (store: IDBObjectStore) {
        store.clear();
      },
      "clear",
    );
    sessionCache.hashesToKeys = {};
  } catch (clearSessionKeyInCacheError) {
    console.log("Failed to clear session cache: ", clearSessionKeyInCacheError);
  }
}

export function initEncryptionCaches() {
  initSessionCache();
  initHashCache();
}

export function clearEncryptionCaches() {
  clearHashCache();
  clearSessionCache();
}
