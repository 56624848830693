import { getDateFromUnixString } from "core/model/utils/dates";
import { getErrorMessage } from "core/model/utils/errors";
import { differenceInDays, getUnixTime } from "date-fns";
import { useEffect } from "react";
import {
  isValidSession,
  useAllowBrowserNotifications,
} from "./NotificationPermission";

// force update service worker every 24 hours
export const useForceUpdateServiceWorker = () => {
  const allow = useAllowBrowserNotifications();
  const localStorageKey = "service-worker-last-updated";
  useEffect(() => {
    if (!allow) return;

    const err = isValidSession();
    if (err) {
      console.info(`could not update service worker: ${err}`);
      return;
    }

    if (Notification.permission === "denied") {
      console.info(
        "will not update service worker: Notification permission denied",
      );
      return;
    }

    const lastUpdated = getDateFromUnixString(
      localStorage.getItem(localStorageKey),
    );

    if (lastUpdated && differenceInDays(lastUpdated, new Date()) === 0) {
      return;
    }

    (async () => {
      try {
        const registration = await navigator?.serviceWorker?.ready;
        if (registration) {
          await registration.update();
          localStorage.setItem(
            localStorageKey,
            getUnixTime(new Date()).toString(),
          );
        } else {
          console.warn("no service worker found to update");
        }
      } catch (err) {
        console.error(`error updating service worker: ${getErrorMessage(err)}`);
      }
    })();
  }, []);
};
