import Config, { ENV_STAGING } from "core/model/config";
import ErrorSnackbar from "./ErrorSnackbar";
import { MINIMUM_DISPLAY_INTERVAL } from "./config";
import useCountdown from "./useCountdown";
import useNetworkChecker from "./useNetworkChecker";

function ConnectedNetworkChecker() {
  const [retryInterval, reset] = useNetworkChecker();
  const count = useCountdown(retryInterval);

  if (retryInterval > MINIMUM_DISPLAY_INTERVAL)
    return <ErrorSnackbar count={count} reset={reset} />;

  return null;
}

export default function NetworkChecker() {
  if (Config.environment === ENV_STAGING) return null;
  return <ConnectedNetworkChecker />;
}
