import { DispatchAction } from "core/types";
import { fromJS } from "immutable";

export default function auth(
  state: {
    settings: { language: string | null };
  } = {
    settings: { language: null },
  },
  action: DispatchAction,
) {
  switch (action.type) {
    case "LANGUAGE_CHANGED":
      return fromJS(state).set("language", action.payload.language).toJS();
    default:
      return state;
  }
}
