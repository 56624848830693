import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { useEnvContext } from "context/EnvContext";
import { DEFAULT_ALGOLIA_QUERY } from "core/consts";
import { AlgoliaAnalyticsName } from "core/types";
import { getIndex } from "dsl/atoms/AlgoliaSearchBar";
import { useGetAnalyticsTags } from "./useGetAnalyticsTags";

export type UseCustomAlgoliaSearchClientProps = {
  algoliaAnalyticsName: AlgoliaAnalyticsName;
  indexName: string;
  indexWithEnv?: boolean;
};

export function useAlgoliaSearchClient({
  algoliaAnalyticsName,
  indexName,
  indexWithEnv,
}: UseCustomAlgoliaSearchClientProps) {
  const { config, env } = useEnvContext();
  const analyticsTags = useGetAnalyticsTags({ algoliaAnalyticsName });
  const indexNameWithEnv = getIndex(indexName, env, indexWithEnv);

  const algoliaClient = algoliasearch(
    config?.algolia.appId ?? "",
    config?.algolia.apiKey ?? "",
  );

  const searchClient: { search: SearchClient["search"] } = {
    ...algoliaClient,
    search(queries) {
      if (
        queries.filter(({ params }) => params?.query !== DEFAULT_ALGOLIA_QUERY)
          .length === 0
      ) {
        return Promise.resolve({ results: [] });
      }
      return algoliaClient.search(queries);
    },
  };

  return { searchClient, analyticsTags, indexNameWithEnv };
}
