import { APP_CAREPROVIDER, APP_CLINIC, TRACK_EVENTS } from "core/consts";
import { PandaTrackerProvider } from "pandatracker-client";
import React, { useCallback } from "react";
import { useTracking } from "react-tracking";

const VERSION = "3";

type PandaSpec = {
  from: string;
  to: string;
  unique: boolean;
};

export type PandaSpecs = {
  [key: string]: PandaSpec;
};

export function configurePanda(app: number): PandaSpecs | undefined {
  switch (app) {
    case APP_CLINIC:
      return {
        clinic_todo: {
          from: "Todo",
          to: "PatientCard",
          unique: true,
        },
        clinic_patient_search: {
          from: "PatientSearch",
          to: "SearchAuctionResponseCard",
          unique: true,
        },
        clinic_patient_request: {
          from: "AuctionResponse",
          to: "AuctionResponseProcess",
          unique: false,
        },
        clinic_patient_request_messenger: {
          from: "AuctionResponse",
          to: "MessengerEvents",
          unique: false,
        },
        clinic_login: {
          from: "LoginPageLoading",
          to: "ClinicDashboard",
          unique: true,
        },
        create_patient: {
          from: "PatientCreatePageLoading",
          to: "PatientAssessment",
          unique: false,
        },
      };
    case APP_CAREPROVIDER:
      return {
        careprovider_dashboard: {
          from: "ReceiverDashboard",
          to: "RequestCard",
          unique: true,
        },
        careprovider_request: {
          from: "AuctionRequest",
          to: "AuctionRequestPageV2",
          unique: false,
        },
        careprovider_request_messenger: {
          from: "AuctionRequest",
          to: "MessengerEvents",
          unique: false,
        },
        careprovider_login: {
          from: "LoginPageLoading",
          to: "CareproviderDashboard",
          unique: true,
        },
      };
    default:
      return undefined;
  }
}

export default function PandaProvider({
  children,
  specs,
}: {
  children: React.ReactNode;
  specs: PandaSpecs | undefined;
}) {
  const { trackEvent } = useTracking();

  const onMeasure = useCallback(
    ({ measures }: { measures: { id: number; measure: number }[] }) => {
      measures.forEach((measure) => {
        trackEvent({
          name: TRACK_EVENTS.PANDA_MEASURES,
          measure_id: measure.id,
          measure: measure.measure,
          panda_version: VERSION,
          system_event: true,
        });
      });
    },

    [],
  );

  return (
    <PandaTrackerProvider specs={specs || {}} onMeasure={onMeasure}>
      {children}
    </PandaTrackerProvider>
  );
}
