import {
  IndexDbSessionKeysDB,
  IndexDbSessionKeysDBVersion,
  IndexDbSessionKeysTableName,
} from "core/types";

// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
const getIndexedDB = () =>
  window.indexedDB ||
  // @ts-ignore
  window.mozIndexedDB ||
  // @ts-ignore
  window.webkitIndexedDB ||
  // @ts-ignore
  window.msIndexedDB ||
  // @ts-ignore
  window.shimIndexedDB;

export function initIndexedDB(
  database: IndexDbSessionKeysDB,
  objectStores: IndexDbSessionKeysTableName[],
  version: IndexDbSessionKeysDBVersion,
) {
  const indexedDB = getIndexedDB();

  // Create the database
  const open = indexedDB.open(database, version);

  // Create the schema
  open.onupgradeneeded = function (e: any) {
    const { newVersion, oldVersion } = e;

    const db = open.result;

    if (oldVersion !== newVersion) {
      const currentStoreNames = db.objectStoreNames;
      Array.from(currentStoreNames).forEach((storeName) => {
        db.deleteObjectStore(storeName);
      });
    }

    objectStores.forEach((objectStore) => {
      db.createObjectStore(objectStore, {
        keyPath: "id",
      });
    });
  };
}

export function callIndexedDB(
  database: IndexDbSessionKeysDB,
  objectStore: IndexDbSessionKeysTableName,
  version: IndexDbSessionKeysDBVersion,
  callback: (store: IDBObjectStore) => void,
  // for debugging, to be removed
  actionType: "clear" | "init" | "set",
) {
  const indexedDB = getIndexedDB();

  // Open the database
  const open = indexedDB.open(database, version);

  open.onsuccess = function () {
    // Start a new transaction
    const db = open.result;

    // check if the object store exists and create it otherwise
    const currentStoreNames = [...db.objectStoreNames];
    const exists = currentStoreNames.includes(objectStore);
    if (!exists) {
      console.log(
        `IndexedDB: Failing to access object store "${objectStore}" in "[${currentStoreNames.join(
          ",",
        )}]" on ${actionType} (version ${version})`,
      );
      return;
    }

    const tx = db.transaction(objectStore, "readwrite");
    const store = tx.objectStore(objectStore);

    callback(store);

    // Close the db when the transaction is done
    tx.oncomplete = function () {
      db.close();
    };
  };
}
