export const beige = {
  50: "#F6F3EF",
  100: "#F3EEE7",
  200: "#EAE2D6",
  300: "#DDCFBB",
  400: "#CFBCA0",
  500: "#C1A885",
  600: "#B4956A",
  700: "#A38152",
  800: "#7A613E",
  900: "#443622",
} as const;
