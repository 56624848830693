import { WEBSOCKET_STATUS } from "core/consts";
import { useSafeState } from "core/hooks";
import { WebSocketStatus } from "core/types";
import { createContext, ReactNode, useContext, useEffect, useRef } from "react";
import { WebSocketConfig } from "../config";

export type WebSocketContext = {
  /**
  Used to update the status of the WebSocket connection. This function should only be used in the
  Apollo WebSocket link, and is set based on the pings to the backend.
  **/
  _setStatus: React.Dispatch<React.SetStateAction<WebSocketStatus>>;
  /**
  The current status of the WebSocket connection.
  The value of isAlive is undefined when the connection status has not been established yet.

  There is a buffer of {KEEP_ALIVE_BUFFER_TIME} to allow for reconnection
  before the connection is declared dusconnected.
  **/
  isAlive: boolean | undefined;
};

export const WebSocketContext = createContext<WebSocketContext>({
  isAlive: undefined,
  _setStatus: (() => {}) as unknown as React.Dispatch<
    React.SetStateAction<WebSocketStatus>
  >,
});

export const useWebSocketContext = () => useContext(WebSocketContext);

const useIsWSAlive = (status: WebSocketStatus) => {
  const [isAlive, setIsAlive] = useSafeState<boolean>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    switch (status) {
      case WEBSOCKET_STATUS.CONNECTED: {
        if (!isAlive) {
          setIsAlive(true);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }
        break;
      }
      case WEBSOCKET_STATUS.ERROR:
      case WEBSOCKET_STATUS.NOT_STARTED:
      case WEBSOCKET_STATUS.CLOSING:
      case WEBSOCKET_STATUS.CLOSED: {
        // give KEEP_ALIVE_BUFFER_TIME secs after close to reconnect before declaring dead
        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            setIsAlive(false);
            timeoutRef.current = undefined;
          }, WebSocketConfig.KEEP_ALIVE_BUFFER_TIME);
        }
        break;
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
  }, [status]);

  return isAlive;
};

export function WebSocketContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [status, _setStatus] = useSafeState<WebSocketStatus>(
    WEBSOCKET_STATUS.NOT_STARTED,
  );
  const isAlive = useIsWSAlive(status);

  return (
    <WebSocketContext.Provider value={{ isAlive, _setStatus }}>
      {children}
    </WebSocketContext.Provider>
  );
}
