export const orange = {
  50: "#FFE9CE",
  100: "#FFD599",
  200: "#FFC370",
  300: "#FFAA33",
  400: "#F58F00",
  500: "#CC6600",
  600: "#B84C08",
  700: "#A73A0C",
  800: "#862F09",
  900: "#391404",
} as const;
