import { isFrance } from "core/model/config";
import { APP_BACKGROUND } from "ds_legacy/materials/colors";
import { dp, padding, sizing } from "ds_legacy/materials/metrics";
import { Display, Subheading } from "ds_legacy/materials/typography";
import { useBrowserContext } from "dsl/atoms/BrowserProvider";
import Image from "dsl/atoms/Image";
import React, { useMemo } from "react";

function BrowserVersion({ label, path }: { label: string; path: string }) {
  return (
    <div
      style={{
        display: "flex",
        height: dp(60),
        marginLeft: dp(20),
        alignItems: "center",
      }}
    >
      <Image style={{ width: sizing(5) }} path={path} alt="" />
      <Subheading>{label}</Subheading>
    </div>
  );
}

export function UnsupportedBrowser({
  browser,
}: {
  browser: { name: string; version: string };
}) {
  const translations: {
    browserDetailsText1: string;
    browserDetailsText2: string;
    recommendedTitle: string;
    subtitle: string;
    title: string;
    versionText: string;
  } = isFrance
    ? {
        title: "Votre navigateur n'est pas compatible.",
        subtitle:
          "Notre système a automatiquement détecté que vous utilisiez le navigateur",
        browserDetailsText1: " version",
        browserDetailsText2: ".",
        recommendedTitle:
          "Veuillez s'il-vous-plait utiliser les navigateurs suivants :",
        versionText: "à partir de la version",
      }
    : {
        title: "Ihr Browser wird nicht unterstützt.",
        subtitle:
          "Unser System hat automatisch festgestellt, dass Sie den Browser",
        browserDetailsText1: " in der Version",
        browserDetailsText2: " verwenden.",
        recommendedTitle: "Wir empfehlen die folgenden Browser zur Nutzung:",
        versionText: "ab Version",
      };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: APP_BACKGROUND,
        padding: padding(10, 0, 0, 10),
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Display>{translations.title}</Display>
      <Subheading>
        <span>
          {translations.subtitle}&nbsp;
          <b>{browser.name}</b>
          {translations.browserDetailsText1}&nbsp;
          <b>{browser.version}</b>
          {translations.browserDetailsText2}
        </span>
      </Subheading>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Subheading>{translations.recommendedTitle}</Subheading>
        <BrowserVersion
          path="chrome_icon.png"
          label={`Chrome (${translations.versionText} 60)`}
        />
        <BrowserVersion
          path="firefox_icon.png"
          label={`Firefox (${translations.versionText} 47)`}
        />
      </div>
    </div>
  );
}

export default function BrowserSupport({
  children,
}: {
  children: React.ReactNode;
}) {
  const { browser, browserName, browserVersion, isFirefox, isIE, isOldEdge } =
    useBrowserContext();

  const isValidBrowser = useMemo(() => {
    if (!browser) return true;

    if (isIE) return false;

    if (isFirefox) {
      return browser.satisfies({
        firefox: ">46",
      });
    }

    if (isOldEdge) return false;

    return true;
  }, [browser]);

  if (isValidBrowser) return <>{children}</>;

  return (
    <UnsupportedBrowser
      browser={{ name: browserName, version: browserVersion }}
    />
  );
}
