import { PROVIDER_SEARCH_VERSION_MAP } from "core/consts";
import { getStaticAsset } from "core/model/config";
import { PROVIDER_SEARCH_CONFIG } from "core/model/providerSearch/config";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function loadFavicon(provider_search: string | undefined) {
  const RECARE_LOGO_ICO = "logo/ico/recare_favicon_lightmode.ico";
  const providerSearch =
    PROVIDER_SEARCH_VERSION_MAP?.[
      provider_search as keyof typeof PROVIDER_SEARCH_VERSION_MAP
    ];
  const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  let iconPath: string = RECARE_LOGO_ICO;

  if (providerSearch) {
    iconPath = PROVIDER_SEARCH_CONFIG[providerSearch].logos.favicon;
  }

  if (!link) {
    console.error("No link found to set favicon");
    return;
  }

  link.href = getStaticAsset(iconPath);
  link.type = "image/x-icon";
}

export default function useLoadFavicon() {
  const { provider_search } = useParams();

  useEffect(() => {
    loadFavicon(provider_search);
  }, [provider_search]);
}
