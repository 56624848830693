import transport from "core/model/api/transport";

export default {
  get(auctionid: number): Promise<any> {
    return transport.get({
      route: `/potentialreceivers/${auctionid}`,
      options: {
        routeName: "auctioneerDebug",
      },
    });
  },
};
