import { AnyObject } from "core/types";
import { fromJS, is } from "immutable";
import React from "react";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, scan, share } from "rxjs/operators";
import { Locale, withTranslations } from "translations";
import Translations from "translations/types";
import { ObservableValue } from "./Context";
import { WithChildren } from "./Form";
import {
  INITIAL_CHANGE_EVENT_TYPE,
  SUBMIT_EVENT_TYPE,
  VALIDATION_FAILED_EVENT_TYPE,
  VALUE_CHANGE_EVENT_TYPE,
  createInitialChangeEvent,
  createSubmitChangeEvent,
  createValidationFailedEvent,
  createValueChangeEvent,
} from "./FormEvents";
import { setValue } from "./StateValueHelpers";
import { Validation } from "./ValidationHelpers";
import { Whitelist } from "./types";

// types
type WithWhitelist = {
  whitelist: Whitelist;
};

type WithTranslations = {
  locale: Locale;
  translations: Translations;
};

type FormContainerWithTranslationsProps = WithChildren &
  WithWhitelist & {
    convertIn: (value: any, props?: FormContainerWithTranslationsProps) => any;
    convertOut: (value: any, props?: FormContainerWithTranslationsProps) => any;
    formInputValue?: AnyObject | null;
    onSubmit: (...args: any) => void;
    resetAfterSubmit?: boolean;
    validate: (value: any, props?: FormContainerWithTranslationsProps) => any;
  };

type FormContainerProps = FormContainerWithTranslationsProps & WithTranslations;

export type FormContainerState = { dirty: boolean };

function getNewValidation(acc: ObservableValue, next: ObservableValue) {
  switch (next.type) {
    case VALUE_CHANGE_EVENT_TYPE:
    case INITIAL_CHANGE_EVENT_TYPE:
    case VALIDATION_FAILED_EVENT_TYPE:
      return next.validation || acc.validation;
    default:
      return acc.validation;
  }
}

function getInitialValue(props: FormContainerProps) {
  return props.convertIn(
    !props.formInputValue ? {} : props.formInputValue,
    props,
  );
}

class FormContainer extends React.Component<
  FormContainerProps,
  FormContainerState
> {
  subject: Subject<ObservableValue>;
  observable: Observable<ObservableValue>;
  valueChangeObs: Observable<ObservableValue>;

  constructor(props: FormContainerProps) {
    super(props);

    this.subject = new Subject<ObservableValue>();

    this.observable = this.subject.pipe(
      scan(
        (acc, next) => {
          switch (next.type) {
            case VALUE_CHANGE_EVENT_TYPE: {
              return {
                type: VALUE_CHANGE_EVENT_TYPE,
                value: setValue(acc.value, next.statePath ?? "", next.newValue),
                oldValue: acc.value,
                statePath: next.statePath,
                validation: getNewValidation(acc, next),
              };
            }
            case SUBMIT_EVENT_TYPE:
              return {
                type: SUBMIT_EVENT_TYPE,
                value: acc.value,
                validation: acc.validation,
              };
            case INITIAL_CHANGE_EVENT_TYPE:
              return {
                type: INITIAL_CHANGE_EVENT_TYPE,
                value: next.newValue,
                oldValue: acc.value,
                validation: getNewValidation(acc, next),
              };
            case VALIDATION_FAILED_EVENT_TYPE:
              return {
                type: VALIDATION_FAILED_EVENT_TYPE,
                value: acc.value,
                validation: next.validation,
              };
            default:
              return acc;
          }
        },
        {
          type: INITIAL_CHANGE_EVENT_TYPE,
          value: getInitialValue(props),
          validation: true,
        } as ObservableValue,
      ),
      share(),
    );

    this.observable
      .pipe(filter((e) => e.type === SUBMIT_EVENT_TYPE))
      .subscribe({
        next: (e) => this.submit(e.value),
      });

    this.valueChangeObs = this.observable.pipe(
      filter((e) => {
        return (
          e.type === VALUE_CHANGE_EVENT_TYPE ||
          e.type === INITIAL_CHANGE_EVENT_TYPE ||
          e.type === VALIDATION_FAILED_EVENT_TYPE
        );
      }),
      share({
        connector: () =>
          new BehaviorSubject<ObservableValue>({
            type: INITIAL_CHANGE_EVENT_TYPE,
            whitelist: props.whitelist,
            value: getInitialValue(props),
            validation: true,
          }),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );

    this.state = {
      dirty: false,
    };
  }

  componentDidUpdate(prevProps: FormContainerProps) {
    const newInitial = getInitialValue(this.props);
    const oldInitial = getInitialValue(prevProps);
    if (
      oldInitial != null &&
      "toJS" in oldInitial &&
      newInitial != null &&
      "toJS" in newInitial
    ) {
      if (newInitial !== oldInitial)
        this.subject.next(createInitialChangeEvent(newInitial));
    } else if (oldInitial == null && newInitial != null) {
      this.subject.next(createInitialChangeEvent(newInitial));
    } else if (oldInitial != null && newInitial != null) {
      if (!is(fromJS(newInitial), fromJS(oldInitial)))
        this.subject.next(createInitialChangeEvent(newInitial));
    }
  }

  componentWillUnmount() {
    this.subject.unsubscribe();
  }

  submit(value: any) {
    if (this.props.onSubmit == null) {
      console.warn(
        "Form hasn't received any onSubmit function as props, you may have forgotten it",
      );
      return;
    }

    const validation = this.props.validate(value, this.props);
    if (validation === true || validation == null) {
      this.props.onSubmit(this.props.convertOut(value, this.props), this.state);
      if (this.state.dirty) {
        this.setState({ dirty: false });
      }

      if (this.props.resetAfterSubmit) {
        this.subject.next(
          createValueChangeEvent(getInitialValue(this.props), "", true),
        );
      }

      return;
    }

    const newValidation = new Validation(validation);

    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed(
        "%c Form validation failed",
        "background: red; color: white",
        "",
      );
      console.log(JSON.stringify(newValidation));
      console.groupEnd();
    }

    this.subject.next(createValidationFailedEvent(newValidation));
  }

  render() {
    return this.props.children({
      onChange: (value, statePath, validation) => {
        if (!this.state.dirty) {
          this.setState({ dirty: true });
        }
        this.subject.next(
          createValueChangeEvent(value, statePath || "", validation),
        );
      },
      valueChangeObs: this.valueChangeObs,
      submit: () => this.subject.next(createSubmitChangeEvent()),
      dirty: this.state.dirty,
    });
  }
}

export const FormContainerWithTranslations = withTranslations(FormContainer);
